/* eslint-disable react/no-unescaped-entities */

import curiousCat from '../../../assets/Cats/curious-cat3.svg'
import teamwork from '../../../assets/Onboarding/teamwork.svg'
import highFive from '../../../assets/Onboarding/highfive.svg'
import hug from '../../../assets/Onboarding/hug.png'
import allKarmaMeme from '../../../assets/Onboarding/get-all-the-meme.jpg'
import survey from '../../../assets/Onboarding/survey.png'
import prizecup from '../../../assets/Onboarding/prize-cup.png'

const GiphyImage: React.FC<{ src: string; alt: string }> = ({ src, alt }) => (
  <div className='flex align-center justify-center text-center'>
    <img
      src={src}
      alt={alt}
      className='w-1/2' // matches your teamwork image size
    />
  </div>
)

const IntroductionPage = () => (
  <div className='max-w-[95%] mx-auto whitespace-normal'>
    <div className='bg-white p-5 rounded-lg'>
      <div className='mt-4 space-y-6'>
        {/* Welcome Section */}
        <div className='flex align-center justify-center text-center'>
          <img src={teamwork} alt='Cute cat' className='w-1/2 md:w-1/4' />
        </div>
        <div>
          <h4 className='font-semibold text-lg mb-2 text-center'>
            👋 Welcome to the SurveySwap Community!
          </h4>
        </div>

        <p>
          We&apos;re super excited to have you join our community! We created
          SurveySwap when we were students ourselves, facing the same challenge
          you might be dealing with now - finding research participants without
          breaking the bank. Today, we&apos;re a global community of students,
          marketers, and researchers all helping each other out.
        </p>
      </div>
    </div>
  </div>
)

const HowItWorks: React.FC = () => (
  <div className='max-w-[95%] mx-auto whitespace-normal'>
    <div className='bg-white p-5 rounded-lg'>
      <div className='mt-4 space-y-6'>
        {/* Title Section */}
        <div>
          <h4 className='font-semibold text-lg mb-4 text-center'>
            How SurveySwap Works
          </h4>
        </div>

        {/* Introduction */}
        <p className='text-center mb-6'>
          SurveySwap runs on Karma. Think of it as your survey currency!
        </p>

        {/* Karma Points Explanation */}
        <div className='space-y-4'>
          {/* Earn Karma */}
          <div className='flex items-start space-x-3'>
            <div className='text-lg flex-shrink-0'>⭐</div>
            <div>
              <p className='font-bold mb-2'>Exchange Karma</p>
              <p className=''>
                By taking surveys, you earn karma, and when people take your
                survey - you give them karma. Think of it as a &ldquo;you help
                me, I help you&rdquo; kind of deal!
              </p>
            </div>
          </div>

          <div className='flex items-start space-x-3'>
            <div className='text-2xl flex-shrink-0'>🏆</div>
            <div>
              <p className='font-bold mb-2'>Work is Rewarded</p>
              <p className='leading-snug'>
                More karma = higher visibility. Your survey moves up in the
                rankings as you help others!
              </p>
            </div>
          </div>
        </div>

        {/* Bottom Note */}
        <p className='text-center text-sm mt-6 '>
          Ready to start earning? Completing this onboarding survey will earn
          your first 10 Karma points!
        </p>
      </div>
    </div>
  </div>
)

// const HowItWorks = () => (
//   <div className='max-w-[95%] mx-auto whitespace-normal'>
//     <div className='bg-white p-5 rounded-lg'>
//       <div className='mt-4 space-y-6'>
//         {/* How It Works Section */}
//         <div>
//           <div>
//             <h4 className='font-semibold text-lg mb-2 text-center'>
//               🤝 How It Works
//             </h4>
//           </div>
//           <p>
//             On SurveySwap, you will find a big list of all the surveys people
//             have posted. By taking surveys, you earn karma, and when people take
//             your survey - you give them karma. Think of it as a &ldquo;you help
//             me, I help you&rdquo; kind of deal!
//           </p>
//         </div>
//         <div className='flex align-center justify-center text-center'>
//           <img src={highFive} alt='Cute cat' className='w-1/2 md:w-1/4' />
//         </div>

//         {/* Support Section */}
//         {/* <div>
//           <h4 className='font-semibold text-lg mb-2'>💬 Need Help?</h4>
//           <p>
//             Got questions? Our support team is just a message away! We always
//             strive to get back to you within 24hrs.
//           </p>
//         </div> */}
//       </div>
//     </div>
//   </div>
// )

const GetToKnowYouBetter = () => (
  <div className='text-center flex justify-center align-center'>
    <h4 className='font-bold mb-4 text-lg2'>Let's get to know you better!</h4>
  </div>
)

const GreatJobSoFar = () => (
  <div className='text-center'>
    <h3 className='font-bold mb-4 text-lg'>Thanks a million!</h3>
    <img
      src='https://cdn.pixabay.com/photo/2024/02/28/07/42/european-shorthair-8601492_640.jpg'
      alt='Cute cat'
      className='max-w-full h-auto rounded-lg'
    />
  </div>
)

const KarmaIntro = () => (
  <div className='max-w-[95%] mx-auto whitespace-normal'>
    <div className='bg-white p-5 rounded-lg'>
      <div className='mt-4 space-y-6'>
        <div className='flex align-center justify-center text-center'>
          <img
            src={allKarmaMeme}
            alt='Karma visualization'
            className='w-1/2 md:w-1/3'
          />
        </div>

        <div className='text-center space-y-3'>
          <h4 className='font-semibold text-lg'>Get all the karma!</h4>
          <p className=''>
            There are several ways to earn karma points on SurveySwap. Let's
            explore them together!
          </p>
        </div>
      </div>
    </div>
  </div>
)

const ReferralKarma = () => (
  <div className='max-w-[95%] mx-auto whitespace-normal'>
    <div className='bg-white p-5 rounded-lg'>
      <div className='mt-4 space-y-6'>
        <div className='flex align-center justify-center text-center'>
          <img src={hug} alt='Friends' className='w-14 h-14' />
        </div>

        <div className='space-y-4'>
          <div className='text-center'>
            <h4 className='font-semibold text-lg mb-2'>Bring Your Friends</h4>
            <p className='mb-2'>
              Growing our community helps everyone get better research results.
              The more researchers we have, the more diverse responses you'll
              get!{' '}
            </p>
            <p className='font-semibold'>
              {' '}
              For each friend who joins and participates, you'll earn 20 karma
              points!
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
)

const TakeSurveys = () => (
  <div className='max-w-[95%] mx-auto whitespace-normal'>
    <div className='bg-white p-5 rounded-lg'>
      <div className='mt-4 space-y-6'>
        <div className='flex align-center justify-center text-center'>
          <img src={survey} alt='Survey' className='w-14 h-14' />
        </div>

        <div className='space-y-4'>
          <div className='text-center'>
            <h4 className='font-semibold text-lg mb-2'>Take Surveys</h4>
            <p className='mb-2'>
              Help fellow researchers by taking their surveys. You'll earn karma
              based on the length of the survey - longer surveys mean more karma
              points!
            </p>
            <p className='font-semibold'>
              After earning your first 50 karma points, get 50% off all karma
              purchases!
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
)

const BuyKarma = () => (
  <div className='max-w-[95%] mx-auto whitespace-normal'>
    <div className='bg-white p-5 rounded-lg'>
      <div className='mt-4 space-y-6'>
        <div className='flex align-center justify-center text-center'>
          <img src={prizecup} alt='Shopping cart' className='w-14 h-14' />
        </div>

        <div className='space-y-4'>
          <div className='text-center'>
            <h4 className='font-semibold text-lg mb-2'>Buy Karma</h4>
            <p className='mb-2'>
              Need responses quickly or don't have time to take surveys? You can
              purchase karma to fast-track your research and get responses right
              away.
            </p>
            <p className='font-semibold'>
              After earning your first 50 karma points, get 50% off all karma
              purchases!
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
)

const QualityControl = () => (
  <div className='max-w-[95%] mx-auto whitespace-normal'>
    <div className='bg-white p-5 rounded-lg'>
      <div className='mt-4 space-y-6'>
        <div>
          <h4 className='font-semibold text-lg mb-4 text-center'>
            🛡️ Ensuring Quality Responses
          </h4>
        </div>

        <div className='space-y-4'>
          {/* Community Monitoring */}
          <div className='flex items-start space-x-3'>
            <div className='text-2xl flex-shrink-0'>👀</div>
            <div>
              <p className='font-bold mb-2'>Community Monitoring</p>
              <p>
                Help us maintain high standards! You can report surveys that
                are:
              </p>
              <ul className='list-disc ml-5 mt-2 space-y-2'>
                <li>
                  Missing karma codes - once we receive a second report, you
                  will get automatically get the karma
                </li>
                <li>Offline or unavailable</li>
                <li>Containing low-quality questions</li>
              </ul>
            </div>
          </div>

          {/* Quality Assurance */}
          <div className='flex items-start space-x-3'>
            <div className='text-2xl flex-shrink-0'>⚖️</div>
            <div>
              <p className='font-bold mb-2'>Our Quality Promise</p>
              <p>
                We have a zero-tolerance policy for low-quality responses. If
                someone rushes through your survey, let us know and we'll refund
                your karma and take action.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

const QualityFeedback = () => (
  <div className='max-w-[95%] mx-auto whitespace-normal'>
    <div className='bg-white p-5 rounded-lg'>
      <div className='mt-4 space-y-6'>
        <div>
          <h4 className='font-semibold text-lg mb-4 text-center'>
            💭 Your Thoughts Matter
          </h4>
        </div>

        <div className='space-y-4'>
          <p className='text-center'>
            We're always looking to improve! How do you feel about our quality
            control system?
          </p>

          <div className='text-left space-y-3'>
            <p className='font-semibold'>What do you think about:</p>
            <ul className='list-disc ml-5 space-y-2'>
              <li>Our karma refund system for reported surveys?</li>
              <li>The community monitoring approach?</li>
              <li>Having a boost system for urgent surveys?</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
)

const GrowTogether = () => (
  <div className='max-w-[95%] mx-auto whitespace-normal'>
    <div className='bg-white p-5 rounded-lg'>
      <div className='mt-4 space-y-6'>
        <div className='flex align-center justify-center text-center'>
          <img
            src='grow-together.svg'
            alt='Growing community illustration'
            className='w-1/2 md:w-1/3'
          />
        </div>

        <div>
          <h4 className='font-semibold text-lg mb-4 text-center'>
            🌱 Let's Grow Together!
          </h4>
        </div>

        <div className='text-center space-y-4'>
          <p>
            Finding SurveySwap helpful? Spread the word and help our community
            grow!
          </p>
          <p>We'd love to hear your ideas on making SurveySwap even better.</p>
        </div>
      </div>
    </div>
  </div>
)

const SurveySwapLimitations = () => (
  <div className='max-w-[95%] mx-auto whitespace-normal'>
    <div className='bg-white p-5 rounded-lg'>
      <div className='mt-4 space-y-6'>
        <div className='space-y-4'>
          <div className='text-center'>
            <h4 className='font-semibold text-lg mb-2'>Important to Know</h4>
            <p>
              To help set the right expectations, here's what to expect on
              SurveySwap:
            </p>
          </div>

          <div className='space-y-3'>
            <div className='flex items-start space-x-3'>
              <div className='text-lg flex-shrink-0'>👥</div>
              <div>
                <p className='font-bold mb-1'>Student-Focused Platform</p>
                <p>
                  Most of our users (80%) are bachelor or PhD students aged
                  17-26. Perfect for academic research, but might not be
                  suitable if you need responses from other demographics.
                </p>
              </div>
            </div>

            <div className='flex items-start space-x-3'>
              <div className='text-lg flex-shrink-0'>🌍</div>
              <div>
                <p className='font-bold mb-1'>Available Countries</p>
                <p>
                  Our community is primarily based in the Netherlands, United
                  Kingdom, Germany, and United States. We might not be the best
                  fit if you need responses from other regions.
                </p>
              </div>
            </div>

            <div className='flex items-start space-x-3'>
              <div className='text-lg flex-shrink-0'>💰</div>
              <div>
                <p className='font-bold mb-1'>Not a Paid Survey Platform</p>
                <p>
                  SurveySwap is a research exchange platform, not a place to
                  earn money taking surveys.
                </p>
              </div>
            </div>

            <div className='flex items-start space-x-3'>
              <div className='text-lg flex-shrink-0'>🎯</div>
              <div>
                <p className='font-bold mb-1'>No Specific Targeting</p>
                <p>
                  While our user base consists mainly of students, we can't
                  guarantee responses from specific fields of study or other
                  demographic criteria.
                </p>
              </div>
            </div>

            <div className='flex items-start space-x-3'>
              <div className='text-lg flex-shrink-0'>📊</div>
              <div>
                <p className='font-bold mb-1'>No Response Guarantees</p>
                <p>
                  Users choose which surveys to take. While karma helps increase
                  visibility, responses aren't guaranteed.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className='text-center space-y-2'>
          <p className='text-sm'>Need a specific sample?</p>
          <a
            href='https://surveyswap.io/agency'
            target='_blank'
            rel='noopener noreferrer'
            className='inline-flex items-center text-blue-800 hover:text-black font-medium'
          >
            Check out our Agency Service
            <svg
              className='w-4 h-4 ml-1'
              viewBox='0 0 20 20'
              fill='currentColor'
            >
              <path
                fillRule='evenodd'
                d='M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z'
                clipRule='evenodd'
              />
            </svg>
          </a>
        </div>
      </div>
    </div>
  </div>
)

export {
  IntroductionPage,
  HowItWorks,
  GetToKnowYouBetter,
  GreatJobSoFar,
  KarmaIntro,
  ReferralKarma,
  TakeSurveys,
  BuyKarma,
  QualityControl,
  QualityFeedback,
  GrowTogether,
  SurveySwapLimitations,
}
