import React from 'react'
import ReactDOMServer from 'react-dom/server'

export const renderReactToHtml = (Component: React.FC): string => {
  return ReactDOMServer.renderToString(React.createElement(Component))
}

export const wrapInSurveyElement = (html: string): string => {
  return `<div class="sd-element--with-frame sd-element sd-question sd-row__question sd-row__question--small">${html}</div>`
}
export const wrapInSurveyElementWithoutFrame = (html: string): string => {
  return `
      <div class="sd-element sd-question sd-row__question sd-row__question--small">
        <div class="max-w-2xl mx-auto px-4"> <!-- Added container classes -->
          ${html}
        </div>
      </div>
    `
}
