/**
 * Credits balance interface
 */
export interface ICreditsBalance {
  amount?: number
  amount_needed?: number
  amount_reserved?: number
  total_spent?: number
  total_collected?: number
}

/**
 * Credits Balance model.
 */
export class CreditsBalance {
  amount?: number
  amount_needed?: number
  amount_reserved?: number
  total_spent?: number
  total_collected?: number

  constructor(props: ICreditsBalance) {
    this.amount = props.amount
    this.amount_needed = props.amount_needed
    this.amount_reserved = props.amount_reserved
    this.total_spent = props.total_spent
    this.total_collected = props.total_collected
  }
}
